import { assembly, erp, stations } from '@/services';
import { segment } from '@/services/segment';

export const SET_LOADING = 'SET_LOADING';
export const SET_STATION = 'SET_STATION';
export const SET_TASK = 'SET_TASK';
export const SET_PACKED_ARTICLES = 'SET_PACKED_ARTICLES';
export const PACK_ARTICLE = 'PACK_ARTICLE';
export const PACK_ARTICLES = 'PACK_ARTICLES';
export const SET_TASK_START = 'SET_TASK_START';

export default {
  actions: {
    setDefaults({ commit }, value) {
      commit(SET_STATION, {
        id: null,
        name: '',
        articles: [],
      });
      commit(SET_TASK, null);
      commit(SET_PACKED_ARTICLES, []);
    },
    setLoading({ commit }, value) {
      commit(SET_LOADING, value);
    },
    async startStation({ commit, state }, id) {
      const station = (await stations.start(id, { params: { with: 'packer' } }))
        .data;
      commit(SET_STATION, station);
    },
    async runStation({ commit, state, getters }, id) {
      commit(SET_LOADING, true);

      const taskResponse = await stations.run(id, {
        type: getters.getTaskType,
      });

      const task = taskResponse.data.task;
      commit(SET_LOADING, false);

      if (task) {
        commit(SET_TASK, task);
        // When the task is started, we set the task start tim
        commit(SET_TASK_START, new Date());
      }
    },
    async getNextTask({ commit, state, dispatch, getters }) {
      commit(SET_LOADING, true);

      // get the duration of the task from the task start time
      const duration =
        (new Date().getTime() - getters.taskStart.getTime()) / 1000;

      const getTotalQuantity = (articles) =>
        articles.map((it) => it.quantity).reduce((sum, a) => sum + a, 0);

      if (getters.isQualityControlStation) {
        // send the event to Segment
        segment.track({
          event: 'Order Packed',
          properties: {
            station_id: state.station.id,
            order_id: state.task.id,
            org_order_id: Number(state.task.foreign_id),
            no_of_people: Number(
              state.task.full_product_name.split('-')[1].trim().substr(0, 1)
            ),
            no_of_recipes: state.task.combinations.length,
            has_gift: !!state.task.shipment.customer.gift,
            duration,
          },
        });

        await assembly.orderPacked({
          station_id: state.station.id,
          order_id: state.task.id,
          articles: state.packedArticles,
        });
      } else if (getters.isOrderStation) {
        // send the event to Segment
        segment.track({
          event: 'Articles Placed',
          properties: {
            station_id: state.station.id,
            order_id: state.task.order.id,
            org_order_id: Number(state.task.order.foreign_id),
            total_article: state.packedArticles.length,
            total_quantity: getTotalQuantity(state.packedArticles),
            duration,
          },
        });

        await assembly.bulkRecipesPacked({
          station_id: state.station.id,
          recipe_ids: state.task.recipes.map((it) => it.id),
          articles: state.packedArticles,
        });
      } else {
        // send the event to Segment
        segment.track({
          event: 'Recipe Packed',
          properties: {
            station_id: state.station.id,
            recipe_id: state.task.id,
            order_id: state.task.order.id,
            org_order_id: Number(state.task.order.foreign_id),
            total_article: state.packedArticles.length,
            total_quantity: getTotalQuantity(state.packedArticles),
            duration,
          },
        });

        await assembly.packed({
          station_id: state.station.id,
          recipe_id: state.task.id,
          articles: state.packedArticles,
        });
      }

      commit(SET_PACKED_ARTICLES, []);
      commit(SET_TASK, null);
      commit(SET_LOADING, false);

      await dispatch('runStation', state.station.id);
    },
    packArticle({ commit }, articleForeignId) {
      commit(PACK_ARTICLE, articleForeignId);
    },
    packArticles({ commit }, articleForeignIds) {
      commit(PACK_ARTICLES, articleForeignIds);
    },
    async getOrderChangeRequests({ commit, state }, orderId) {
      commit(SET_LOADING, true);
      const { data } = await erp.getOrderChangeRequests({
        order_id: orderId,
        status: 'pending',
      });
      commit(SET_LOADING, false);
      return data.changeRequests;
    },
    async confirmChangeRequest({ commit, state }, id) {
      commit(SET_LOADING, true);
      const { data } = await erp.updateOrderChangeRequests(id, {
        status: 'completed',
      });
      commit(SET_LOADING, false);
      return data.changeRequest;
    },
  },
  getters: {
    getOrder(state) {
      return state.task
        ? {
            id: state.task?.order?.id || null,
            firstName: state.task?.order?.customer?.first_name || null,
            lastName: state.task?.order?.customer?.last_name || null,
            fullProductName: state.task?.order?.full_product_name || null,
            foreignId: state.task?.order?.foreign_id || null,
          }
        : null;
    },
    isQualityControlStation(state) {
      return state.station.flag === 'quality-control';
    },
    isOrderStation(state) {
      return state.station.type === 'order';
    },
    getTaskType(state, getters) {
      return getters.isQualityControlStation ? 'order' : 'recipe';
    },
    taskStart(state) {
      return state.taskStart;
    },
  },
  mutations: {
    [SET_LOADING](state, value) {
      state.loading = value;
    },
    [SET_STATION](state, value) {
      state.station = value;
    },
    [SET_TASK](state, value) {
      state.task = value;
    },
    [SET_PACKED_ARTICLES](state, value) {
      state.packedArticles = value;
    },
    [PACK_ARTICLE](state, value) {
      const articleToPack = state.task.articles
        .filter((item) => item.status !== 'packed')
        .find((recipeArticle) => recipeArticle.foreign_id === value);

      if (articleToPack) {
        articleToPack.status = 'packed';

        state.packedArticles.push({
          article_id: articleToPack.id,
          article_foreign_id: articleToPack.foreign_id,
          quantity: articleToPack.quantity,
        });
      }
    },
    [PACK_ARTICLES](state, value) {
      const articlesToPack = state.task.recipes
        .map((r) => r.articles)
        .flat()
        .filter((item) => item.status !== 'packed');
      // check if any articles are left to pack
      if (!articlesToPack.length) return;

      value.forEach((id) => {
        const item = articlesToPack.find(
          (article) => article.foreign_id === id
        );
        if (item) {
          item.status = 'packed';

          state.packedArticles.push({
            article_id: item.id,
            article_foreign_id: item.foreign_id,
            quantity: item.quantity,
          });
        }
      });
    },
    [SET_TASK_START](state, value) {
      state.taskStart = value;
    },
  },
  namespaced: true,
  state: {
    station: {
      id: null,
      name: '',
      flag: '',
      articles: [],
    },
    packedArticles: [],
    task: null,
    loading: false,
    taskStart: null,
  },
};
