import { auth } from '@/services';
import { getCookie, setCookie, deleteCookie } from '@/utils/cookieUtils';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export default {
  actions: {
    async login({ commit }, { customer, apiKey }) {
      if (apiKey?.key && apiKey?.ttl) {
        setCookie('api_key', apiKey.key, apiKey.ttl);
        setCookie('type', 'controller');
      }

      if (!customer) {
        customer = (await auth.me()).customer;
      }

      const user = {
        id: customer.id,
        name: customer.user.firstName,
        permissions: customer.user.permissions,
        roles: customer.user.roles,
        email: customer.user.email,
        phone: customer.user.phone,
        updated_at: customer.updatedAt,
      };

      commit(LOGIN, user);
    },
    async loginPacker({ commit }, token) {
      if (token?.token && token?.expires_in) {
        const { token: apiKey, expires_in: expiresIn } = token;

        setCookie('api_key', apiKey, expiresIn);
        setCookie('type', 'packer');
      }

      const user = await auth.mePacker();

      commit(LOGIN, user);
    },
    async logout({ commit }) {
      commit(LOGOUT);
    },
    async restore({ dispatch }) {
      try {
        const type = getCookie('type');
        if (type === 'controller') {
          await dispatch('login', {});
        } else if (type === 'packer') {
          await dispatch('loginPacker');
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 401) {
          console.error('Unable to restore your session.');
        } else {
          console.error(ex);
        }
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    getFirstLetter(state) {
      return state.user?.name.charAt(0).toUpperCase() || 'A';
    },
    roles(state) {
      return state.user ? state.user.roles : [];
    },
    permissions(state) {
      return state.user ? state.user.permissions : [];
    },
  },
  mutations: {
    [LOGIN](state, value) {
      state.user = value;
    },
    [LOGOUT](state) {
      state.user = null;
      deleteCookie('api_key');
      deleteCookie('type');
    },
  },
  namespaced: true,
  state: {
    user: null,
  },
};
