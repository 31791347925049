import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default {
  install(Vue) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.2,
      logErrors: true,
      tracingOptions: {
        trackComponents: true,
      },
      attachProps: true,
      attachStacktrace: true,
      environment: process.env.VUE_APP_ENV,
    });
  },
};
