// TODO: Create a public package for use of this in other projects which don't use shared-js
// ***IMPORTANT: IF YOU MAKE ANY CHANGES HERE, REMEMBER TO DO IT IN OTHER PROJECTS***
class DDLogger {
  constructor($log, { isClient, isServer, env, project }) {
    this.$log = $log || null;
    this.env = env || 'development';
    this.isClient = isClient || true;
    this.isServer = isServer || false;
    this.project = project;

    // optionals
    this.response = {};
    this.config = {};
    this.route = null;
    this.tags = {};
    this.name = '';
    this.journey = '';
  }

  setInstance($log) {
    this.$log = $log;
    return this;
  }

  setIsClient() {
    this.isClient = true;
    this.isServer = false;
    return this;
  }

  setIsServer() {
    this.isServer = true;
    this.isClient = false;
    return this;
  }

  setProject(project) {
    this.project = project;
    return this;
  }

  setRoute(route) {
    this.route = route;
    return this;
  }

  setEnv(env) {
    this.env = env || this.env;
    return this;
  }

  setName(name = '') {
    this.name = name;
    return this;
  }

  setTags(tags = {}) {
    this.tags = tags;
    return this;
  }

  setConfig(config = {}) {
    this.config = config;
    return this;
  }

  setResponse(response = {}) {
    this.response = response;
    return this;
  }

  sendLogs() {
    if (this.$log) {
      const logData = this._buildLogPayload();

      try {
        if (this.isServer) {
          this.$log.info(logData);

          return this._successLogResponse();
        }

        if (this.isClient) {
          this.$log.logger.log(logData);

          return this._successLogResponse();
        }
      } catch (ex) {
        return this._failedLogResponse(ex.message);
      }

      return;
    }

    return this._failedLogResponse(
      'No Log instance set or incomplete log data'
    );
  }

  startJourney(name = '') {
    this.journey = name;

    return this;
  }

  endJourney() {
    this.journey = '';
    return this;
  }

  _formatTags(tags) {
    return Object.entries(tags).map(([key, value]) => {
      return `${key}:${value}`;
    });
  }

  _buildLogPayload() {
    let duration = 0;
    let kongDuration = 0;
    let clientDuration = 0;

    if (this.config.metadata && this.config.metadata.startTime) {
      const endTime = new Date().getTime();
      duration = clientDuration = endTime - this.config.metadata.startTime;
    }

    if (
      this.response.headers &&
      this.response.headers['x-kong-upstream-latency']
    ) {
      duration = kongDuration = parseInt(
        this.response.headers['x-kong-upstream-latency']
      );
    }

    const status = this.response.status || 500;

    let statusMessage = 'ok';

    if (status >= 500) {
      statusMessage = 'error';
    } else if (status >= 400) {
      statusMessage = 'warning';
    }

    // default tags can be extended if more tags is set
    const tags = Object.assign(
      {
        name: this.route.name,
        user_journey: this.name,
        env: this.env,
      },
      this.tags
    );

    const formattedTags = this._formatTags(tags).join(',');

    const logData = {
      http: {
        status_code: status,
      },
      env: this.env,
      host: this.config.baseURL,
      service: this.isServer
        ? `server-${this.project}`
        : `client-${this.project}`,
      ddsource: this.isServer ? 'nodejs' : 'browser',
      url: this.config.url,
      duration,
      status: statusMessage,
      ddtags: formattedTags,
      timings: {
        client: clientDuration,
        kong: kongDuration,
      },
      slo: {
        journey: this.name,
      },
    };

    // has config
    if (this.config) {
      // headers
      if (this.config.headers && this.config.headers['user-agent']) {
        logData.http.useragent = this.config.headers['user-agent'];
      }

      logData.http.method = this.config.method;
      logData.http.url = this.config.url;
    }

    // has route
    if (this.route) {
      logData.http.referer = this.route.path;
    }

    // reset journey name
    this.name = '';

    return logData;
  }

  _successLogResponse(message) {
    return {
      error: false,
      message: message || 'Log success',
    };
  }

  _failedLogResponse(message) {
    return {
      error: false,
      message: message || 'Failed to send datadog logs',
    };
  }
}

export default DDLogger;
