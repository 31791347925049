/**
 * Cookie helpers from https://www.tabnine.com/academy/javascript/how-to-set-cookies-javascript/
 * @param cookieName
 */
const getCookie = (cookieName) => {
  const name = cookieName + '=';
  const cDecoded = decodeURIComponent(document.cookie); // sanitize cookie from encoding
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

/**
 * @param cookieName
 * @param value string cookie value
 * @param ttl integer in seconds
 */
const setCookie = (cookieName, value, ttl) => {
  const date = new Date();
  const ms = ttl * 1000; // ttl in milliseconds
  date.setTime(date.getTime() + ms);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = cookieName + '=' + value + '; ' + expires + '; path=/';
};

/**
 * @param cookieName
 */
const deleteCookie = (cookieName) => {
  document.cookie =
    cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
};

export { getCookie, setCookie, deleteCookie };
